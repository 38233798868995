import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article23112020 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              S&T MOLD CELEBRATED ITS 25TH ANNIVERSARY. WE LOVE, WHAT WE DO!
            </h1>
            <p>
              The company S&T Mold was founded in 1995 and has rightfully won a
              leading position in Moldovan Market of system integration and has
              become one of the best providers of IT services in the Republic of
              Moldova.
            </p>
            <p>
              The corporate culture of the company was initially based on the
              key principles of professionalism, impeccable business reputation
              and dedication to one's business. It adheres to the quiet
              principles in relations with customers and suppliers and this
              became the foundation for company growth. “We love IT” is not just
              a slogan, but a promise that S&T keeps to its customers every day:
              First-class and dedicated IT services that help to create the
              necessary space for innovation.
            </p>
            <p>
              For the company, this is not only a quarter of a century of growth
              and development, but also achievements and victories jointly with
              partners.
            </p>
            <p>
              We thank all our partners and we look forward to continuing our
              successful cooperation.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article23112020;
